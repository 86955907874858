import './assets/Styles/style.css';
import './assets/Styles/table.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-modern-drawer/dist/index.css';
import 'react-quill/dist/quill.snow.css';
import React, { useEffect, Suspense, lazy } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import BeforeLoginRoute from './Helper/BeforeLoginRoute';
import { useSelector } from 'react-redux';
import ProtectedRouter from './Helper/ProtectedRoute';
import Loader from './Component/Loader';
import LiquorAPA from './pages/AgreementTemplates/LiquorAPA';
import CompanyFolderDetail from './pages/TeamFolder/CompanyFolderDetail';
import BrokerFolderDetail from './pages/TeamFolder/BrokerFolderDetail';
import ListingFolderDetailContainer from './pages/TeamFolder/ListingFolderDetailContainer';
import NotFound from './pages/NotFound';
import UpdatePassword from './pages/UpdatePassword';
import EditProfile from './pages/EditProfile';
// import GuestMessages from './pages/GuestMessages';
import ManageAdmins from './pages/ManageAdmins';
import { RuntimeDataProvider } from './contexts/RuntimeDataContext';
import SideBarSkeleton from './Component/SideBarSkeleton';
import { changeMomentTimeZone } from './utils/timeUtils';
import { TokenProvider } from './contexts/TokenContext';
const Listings = lazy(() => import('./pages/Listings'));
const Commission = lazy(() => import('./pages/Commission'));
// import SellerFolders from "./pages/TeamFolder/SellerFolders";
const LeadListing = lazy(() => import('./pages/LeadListing'));
const DraftListing = lazy(() => import('./pages/DraftListing'));
const StandardAPA = lazy(
  () => import('./pages/AgreementTemplates/StandardAPA'),
);
const ListingAPA = lazy(() => import('./pages/AgreementTemplates/ListingAPA'));
const Home = lazy(() => import('./pages/Home'));
const HomePage = lazy(() => import('./pages/Home/HomePage'));
const AddListing = lazy(() => import('./pages/AddListing'));
const TaskManagerAllProjectsContainer = lazy(
  () => import('./pages/TaskManager/TaskManagerAllProjects/container'),
);
const SelectedProjectTaskManager = lazy(
  () => import('./pages/TaskManager/SelectedProjectTaskManager'),
);
const AllTasks = lazy(
  () => import('./pages/AllTasks'),
);
const TaskManagerTemplateTask = lazy(
  () => import('./pages/TaskManager/TaskManagerTemplateTask'),
);
const Calendar = lazy(() => import('./pages/Calendar'));
const EditListingContainer = lazy(() => import('./pages/EditListing/container'));
const Listing = lazy(() => import('./pages/Listing'));
const ListingDetail = lazy(() => import('./pages/ListingDetail'));
const ContactManagement = lazy(() => import('./pages/ContactManagement'));
const Login = lazy(() => import('./pages/Login'));
const ViewListingContainer = lazy(() => import('./pages/ViewListing/container'));
const ViewInterest = lazy(() => import('./pages/ViewInterest'));
const PreviewListing = lazy(() => import('./pages/PreviewListing'));
const TeamFolder = lazy(() => import('./pages/TeamFolder'));
const Conversation = lazy(() => import('./pages/Conversation'));
const ConversationNew = lazy(() => import('./pages/Conversation/index-new'));
const Email = lazy(() => import('./pages/Email'));
const Market = lazy(() => import('./pages/Market'));
const Marketing = lazy(() => import('./pages/Marketing'));
const Messages = lazy(() => import('./pages/Messages'));
const InterestsContainer = lazy(() => import('./pages/Interests/container'));
const EditInterestsProfile = lazy(
  () => import('./pages/Interests/EditProfile'),
);
const ContactUs = lazy(() => import('./pages/WebsiteForms/ContactUs'));
const FreeEvaluation = lazy(
  () => import('./pages/WebsiteForms/FreeEvaluation'),
);
const PrefferedBusiness = lazy(
  () => import('./pages/WebsiteForms/PrefferedBusiness'),
);
const PrefferedBusinessDetail = lazy(
  () => import('./pages/WebsiteForms/PrefferedBusinessDetail'),
);
const AllUser = lazy(() => import('./pages/PlatformUsers/AllUser'));
const AllBroker = lazy(() => import('./pages/PlatformUsers/AllBroker'));
const BrokerDetail = lazy(() => import('./pages/PlatformUsers/BrokerDetail'));
const UserDetail = lazy(() => import('./pages/PlatformUsers/UserDetail'));
const TaskManagerAllTemplates = lazy(
  () => import('./pages/TaskManager/TaskManagerAllTemplates'),
);
const ViewTeamFolderDetail = lazy(
  () => import('./pages/TeamFolder/ViewTeamFolderDetail'),
);
const OutsideUserDetail = lazy(
  () => import('./pages/PlatformUsers/OutsideUserDetail'),
);
const Reports = lazy(() => import('./pages/Reports'));
const Groups = lazy(() => import('./pages/Groups'));

const BuyABusiness = lazy(() => import('./pages/Cms/BuyABusiness'));

// Cruds Routes
const Categories = lazy(() => import('./pages/Cruds/Categories'));
const Status = lazy(() => import('./pages/Cruds/Status'));
const UserType = lazy(() => import('./pages/Cruds/UserType'));
const TokenManagement = lazy(() => import('./pages/TokenManagement'));

const LazyWrapper = ({ component: Component, ...props }) => (
  <Suspense
    fallback={
      <SideBarSkeleton>
        <Loader />
      </SideBarSkeleton>
    }
  >
    <Component {...props} />
  </Suspense>
);

function App() {
  const { user } = useSelector((state) => state.authReducer);
  const { hasNotification } = useSelector((state) => state.commonReducer);
  const isAdmin = !user?.role?.includes('broker');
  const isBroker = user?.role?.includes('broker');
  const isOnlySuperAdmin = user?.role?.includes('super-admin');
  const isAllowCMS = user?.role?.some((item) =>
    ['admin', 'executive', 'hr', 'sales']?.includes(item),
  );

  useEffect(() => {
    changeMomentTimeZone(user?.timezone);
  }, [user])

  useEffect(() => {
    const updateFavicon = () => {
      const favicon = document.querySelector('link[rel="icon"]');
      const badgeIconURL = hasNotification ? '/favicon1.png' : '/favicon.png';
      favicon.href = badgeIconURL;
    };

    updateFavicon(); // Call the function when 'hasNotifications' changes
  }, [hasNotification]);

  return (
    <RuntimeDataProvider>
      <ToastContainer />

      <BrowserRouter>
        <TokenProvider>
          <Routes>
            <Route
              path="/login"
              exact
              element={
                <BeforeLoginRoute element={<LazyWrapper component={Login} />} />
              }
            />
            {/* <Route
              path="/"
              exact
              element={
                <ProtectedRouter element={<LazyWrapper component={HomePage} />} />
              }
            /> */}
            <Route
              path="/"
              exact
              element={
                <ProtectedRouter element={<LazyWrapper component={Home} />} />
              }
            />
            <Route
              path="/listings"
              exact
              element={
                <ProtectedRouter element={<LazyWrapper component={Listings} />} />
              }
            />
            {isOnlySuperAdmin || isBroker 
            ? (<Route
                path="/commission"
                exact
                element={
                  <ProtectedRouter element={<LazyWrapper component={Commission} />} />
                }
              />) 
            : <></>}
            <Route
              path="/contact-management"
              exact
              element={
                <ProtectedRouter element={<LazyWrapper component={ContactManagement} />} />
              }
            />
            <Route
              path="/listing-detail/:slug/"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={ListingDetail} />}
                />
              }
            />
            <Route
              path="/reports"
              exact
              element={
                <ProtectedRouter element={<LazyWrapper component={Reports} />} />
              }
            />
            <Route
              path="/conversation/:slug?"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={Conversation} />}
                />
              }
            />
            <Route
              path="/conversation-new"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={ConversationNew} />}
                />
              }
            />
            <Route
              path="/reports"
              exact
              element={
                <ProtectedRouter element={<LazyWrapper component={Reports} />} />
              }
            />
            {/* {isAdmin && ( */}
            <Route
              path="/add-listing"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={AddListing} />}
                />
              }
            />
            <Route
              path="/edit-listing/:slug"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={EditListingContainer} />}
                />
              }
            />
            {/* <Route
              path="/draft-listings"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={DraftListing} />}
                />
              }
            /> */}
            {/* <Route
              path="/lead-listings"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={LeadListing} />}
                />
              }
            /> */}
            {/* )} */}
            <Route
              path="/view-listing/:slug"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={ViewListingContainer} />}
                />
              }
            />

            <Route
              path="/preview-listing/:slug"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={PreviewListing} />}
                />
              }
            />

            <Route
              path="/task-manager"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={TaskManagerAllProjectsContainer} />}
                />
              }
            />
            <>
              <Route
                path="/all-template"
                exact
                element={
                  <ProtectedRouter
                    element={<LazyWrapper component={TaskManagerAllTemplates} />}
                  />
                }
              />
              <Route
                path="/template-task/:slug"
                exact
                element={
                  <ProtectedRouter
                    element={<LazyWrapper component={TaskManagerTemplateTask} />}
                  />
                }
              />
            </>
            <Route
              path="/task-manager/:slug"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={SelectedProjectTaskManager} />}
                />
              }
            />
            <Route
              path="/tasks"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={AllTasks} />}
                />
              }
            />
            <Route
              path="/calendar"
              exact
              element={
                <ProtectedRouter element={<LazyWrapper component={Calendar} />} />
              }
            />
            <Route
              path="/view-interest/:id"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={ViewInterest} />}
                />
              }
            />
            <Route
              path="/interests"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={InterestsContainer} />}
                />
              }
            />
            <Route
              path="/team-folder"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={TeamFolder} />}
                />
              }
            />
            <Route
              path="/team-folder/company/:id"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={CompanyFolderDetail} />}
                />
              }
            />
            <Route
              path="/team-folder/:id"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={ViewTeamFolderDetail} />}
                />
              }
            />
            <Route
              path="/team-folder/broker/:id"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={BrokerFolderDetail} />}
                />
              }
            />
            <Route
              path="/team-folder/listing/:id"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={ListingFolderDetailContainer} />}
                />
              }
            />
            {/* <Route
              path="/seller-folder"
              exact
              element={<ProtectedRouter element={<LazyWrapper component={SellerFolders} />} />}
            /> */}
            <Route
              path="/email"
              exact
              element={
                <ProtectedRouter element={<LazyWrapper component={Email} />} />
              }
            />
            <Route
              path="/market"
              exact
              element={
                <ProtectedRouter element={<LazyWrapper component={Market} />} />
              }
            />
            <Route
              path="/email-templates"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={Marketing} />}
                />
              }
            />
            <Route
              path="/messages"
              exact
              element={
                <ProtectedRouter element={<LazyWrapper component={Messages} />} />
              }
            />

            <Route
              path="/contact-us"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={ContactUs} />}
                />
              }
            />
            {isAdmin && (
              <>
                <Route
                  path="/free-evaluation"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={FreeEvaluation} />}
                    />
                  }
                />
                <Route
                  path="/preferred-business"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={PrefferedBusiness} />}
                    />
                  }
                />
                <Route
                  path="/preferred-business/:id"
                  exact
                  element={
                    <ProtectedRouter
                      element={
                        <LazyWrapper component={PrefferedBusinessDetail} />
                      }
                    />
                  }
                />

                <Route
                  path="/all-user"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={AllUser} />}
                    />
                  }
                />
                <Route
                  path="/user-detail/:slug"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={UserDetail} />}
                    />
                  }
                />
                <Route
                  path="/all-broker"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={AllBroker} />}
                    />
                  }
                />
                <Route
                  path="/broker-detail/:slug"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={BrokerDetail} />}
                    />
                  }
                />
                {/* same route for two pages for admin roles and outside user roles */}
                <Route
                  path="/admin-user-detail/:slug"
                  exact
                  element={
                    <ProtectedRouter
                      element={<OutsideUserDetail title={'Admin User Detail'} />}
                    />
                  }
                />
                <Route
                  path="/outside-user-detail/:slug"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={OutsideUserDetail} />}
                    />
                  }
                />

                <Route
                  path="/free-evaluation"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={FreeEvaluation} />}
                    />
                  }
                />
                <Route
                  path="/preferred-business"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={PrefferedBusiness} />}
                    />
                  }
                />
                <Route
                  path="/preferred-business/:id"
                  exact
                  element={
                    <ProtectedRouter
                      element={
                        <LazyWrapper component={PrefferedBusinessDetail} />
                      }
                    />
                  }
                />
                {/* cms and cruds */}

                {isAllowCMS && (
                  <>
                    <Route
                      path="/categories"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={Categories} />}
                        />
                      }
                    />
                    <Route
                      path="/status"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={Status} />}
                        />
                      }
                    />
                    <Route
                      path="/user-type"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={UserType} />}
                        />
                      }
                    />
                    {/* <Route
                      path="/faqs"
                      exact
                      element={<ProtectedRouter element={<LazyWrapper component={Faqs />} />}
                    />
                    <Route
                      path="/reviews"
                      exact
                      element={<ProtectedRouter element={<LazyWrapper component={HomeReviews />} />}
                    />
                    <Route
                      path="/our-team"
                      exact
                      element={<ProtectedRouter element={<LazyWrapper component={OurTeam />} />}
                    />
                    <Route
                      path="/core-values"
                      exact
                      element={<ProtectedRouter element={<LazyWrapper component={CoreValues />} />}
                    /> */}

                    {/* <Route
                      path="/cms/home"
                      exact
                      element={<ProtectedRouter element={<LazyWrapper component={HomeCms />} />}
                    /> */}
                    {/* <Route
                      path="/cms/about"
                      exact
                      element={<ProtectedRouter element={<LazyWrapper component={AboutCms />} />}
                    /> */}

                    {/* <Route
                      path="/cms/contact-us"
                      exact
                      element={<ProtectedRouter element={<LazyWrapper component={ContactUsCms />} />}
                    /> */}

                    {/* <Route
                      path="/cms/footer"
                      exact
                      element={<ProtectedRouter element={<LazyWrapper component={FooterCms />} />}
                    /> */}
                    {/* <Route
                      path="/cms/sell-your-business"
                      exact
                      element={
                        <ProtectedRouter element={<LazyWrapper component={SellYourBusinessCms />} />
                      }
                    /> */}

                    <Route
                      path="/cms/buy-a-business"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={BuyABusiness} />}
                        />
                      }
                    />
                    {/* <Route
                      path="/cms/services"
                      exact
                      element={<ProtectedRouter element={<LazyWrapper component={Services />} />}
                    /> */}
                    {/* <Route
                      path="/services"
                      exact
                      element={<ProtectedRouter element={<LazyWrapper component={ServicesCrud />} />}
                    /> */}

                    {/* <Route
                      path="/cms/careers"
                      exact
                      element={<ProtectedRouter element={<LazyWrapper component={CareerCms />} />}
                    /> */}
                  </>
                )}

                <Route
                  path="/groups"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={Groups} />}
                    />
                  }
                />
              </>
            )}
            {isBroker && (
              <>
                <Route
                  path="/all-user"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={AllUser} />}
                    />
                  }
                />
                <Route
                  path="/user-detail/:slug"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={UserDetail} />}
                    />
                  }
                />
                <Route
                  path="/outside-user-detail/:slug"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={OutsideUserDetail} />}
                    />
                  }
                />
                <Route
                  path="/all-broker"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={AllBroker} />}
                    />
                  }
                />
              </>
            )}
            <Route
              path="/agreement-templates/liquorAPA"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={LiquorAPA} />}
                />
              }
            />
            <Route
              path="/agreement-templates/standardAPA"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={StandardAPA} />}
                />
              }
            />
            <Route
              path="/agreement-templates/listingAPA"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={ListingAPA} />}
                />
              }
            />
            <Route
              path="/update-password"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={UpdatePassword} />}
                />
              }
            />
            <Route path="/update-profile" element={<EditProfile />} />
            <Route
              path="/update-profile/:id"
              element={<LazyWrapper component={EditInterestsProfile} />}
            />
            {/* <Route
              path="/support-chat"
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={GuestMessages} />}
                />
              }
            /> */}
            {isOnlySuperAdmin ? (
              <Route
                path="/manage-admins"
                exact
                element={
                  <ProtectedRouter
                    element={<LazyWrapper component={ManageAdmins} />}
                  />
                }
              />
            ) : (
              <></>
            )}
            <Route
              path="/token-management"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={TokenManagement} />}
                />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </TokenProvider>
      </BrowserRouter>
    </RuntimeDataProvider>
  );
}

export default App;
